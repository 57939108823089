(() => {
    window.addEventListener('load', () => {
        const locationsList = document.querySelector('.locations__list ul');
        const locationsLength = locationsList.children.length;

        for (let i = 0; i < locationsLength; i++) {
            locationsList.appendChild(locationsList.children[i].cloneNode(true));
        }
    });
})();
